$white: #fff !default;
$black: #000 !default;

.block.highlight {
  .title h2 {
    @include highlight-title();
  }
  .description p {
    @include introduction();
  }
  .button a {
    @include body-text();
  }

  .highlight-description {
    a {
      @include introduction();
      text-decoration: none;
    }
  }

  &[class*='has--descriptionColor--highlight-custom-color-'] {
    .highlight-description {
      a {
        color: $black !important;
      }
    }
  }
  &.has--descriptionColor--highlight-custom-color-2 {
    .highlight-description {
      a {
        color: $white !important;
      }
    }
  }
}

.block-editor-highlight {
  &[class*='has--descriptionColor--highlight-custom-color-'] {
    .highlight-description {
      a {
        color: $black !important;
      }
    }
  }
  &.has--descriptionColor--highlight-custom-color-2 {
    .highlight-description {
      a {
        color: $white !important;
      }
    }
  }
}
