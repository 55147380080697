@use 'sass:map';

.contenttype-success-story .header-wrapper + .breadcrumbs {
  display: none;
}
.contenttype-document .header-wrapper + .breadcrumbs {
  display: none;
}
.contenttype-blogfolder .header-wrapper + .breadcrumbs {
  display: none;
}
.contenttype-post .header-wrapper + .breadcrumbs {
  display: none;
}

.slot-outer-container {
  margin-top: 0;
  background-color: var(--background-color, $brand-color);
  color: var(--font-color);
}

.q.container:has(.slot-outer-container) + .q.container .blocks-group-wrapper {
  // adjust top container, 0 does not work because of margin collapsing :(
  padding-top: 1px;
}

.q.container:has(.slot-outer-container)
  + .q.container
  .blocks-group-wrapper:has(> .block.image),
.q.container:has(.slot-outer-container)
  + .q.container
  .blocks-group-wrapper:has(> .block.slider) {
  padding-top: 0px;
}

.landing-page-heading.slot-outer-container {
  padding-bottom: 120px;

  // Remove padding in case the first block has the same background color
  &.nopadding {
    padding-bottom: 0;
  }
}

.landing-page-heading .slot-inner-container {
  @include default-container-width();
  @include adjustMarginsToContainer($default-container-width);

  h1 {
    padding-bottom: $spacing-xlarge;
    margin-bottom: 0;
    font-size: map.get($font-sizes, 5xl);
    font-weight: $bold;
    line-height: map-get($line-heights, 5xl);
    text-align: left;

    &:last-child {
      padding-bottom: 0;
    }
  }

  p {
    font-size: map.get($font-sizes, l);
    line-height: map-get($line-heights, xl);
    text-align: left;
  }

  a {
    color: var(--font-color);
    font-weight: $bold;
    line-height: map-get($line-heights, l);
    text-decoration: underline;

    &.external:after {
      display: none !important;
    }

    .icon {
      position: relative;
      top: 4px;
      margin-right: 5px;
    }
  }
}
