#footer {
  --footer-background: $brand-color;
  margin-top: 0;
  background: $brand-color;

  .q.react-aria-TextField
    .react-aria-Label:has(+ .react-aria-Input:not(:placeholder-shown)),
  .q.react-aria-TextAreaField
    .react-aria-Label:has(+ .react-aria-TextArea:not(:placeholder-shown)) {
    margin-top: -30px;
    transform: scale(0.857143);
    transform-origin: 0 0;
  }

  .footer {
    @include default-container-width();
    @include adjustMarginsToContainer($default-container-width);
    padding: $spacing-xlarge 0px;
    background: $brand-color;
    color: $white;

    .footer-form {
      .contact {
        margin-bottom: $spacing-xlarge;
        font-size: 48px;
        line-height: 56px;
        text-align: left;
      }

      .extra-information {
        font-size: 24px;
        line-height: 33px;
        text-align: left;
      }

      form {
        display: flex;
        flex-flow: column;
        margin-top: $spacing-xlarge;

        .first-row {
          display: flex;
          flex: 1 1 0;
          gap: 18px;

          @media only screen and (max-width: $tablet-breakpoint) {
            flex-flow: column;
          }
        }

        .custom-input-group {
          flex-grow: 1;
          margin-bottom: $spacing-xlarge;

          label {
            left: 0;
            margin-left: 0;
            color: $white;
            font-weight: 400;
          }

          input,
          textarea {
            padding-left: 0;
            border: unset;
            border-radius: unset;
            border-bottom: 1px solid $white;
            background: transparent;
            color: $white;
            &:focus {
              border-bottom: 10px solid $white;
              margin-bottom: -10px;
              box-shadow: none;
            }

            &[data-invalid] {
              border-bottom: 1px solid red;
              margin-bottom: -1px;
            }
            &[data-invalid][data-focused] {
              border-bottom: 10px solid red;
              margin-bottom: -10px;
            }
          }

          input::selection,
          textarea::selection {
            color: $white;
          }

          .react-aria-TextField {
            width: 100%;
          }
        }

        .form-button {
          margin-bottom: $spacing-xlarge;
          text-align: left;

          button {
            display: inline-flex;
            align-items: center;
            padding-left: 0px;
            border: unset;
            background: transparent;
            color: $white;
            cursor: pointer;
            gap: 5px;
            svg {
              margin-left: -2px;
            }

            span {
              font-weight: 700;
              line-height: 30px;
              text-decoration: underline;
            }
          }
        }
      }
    }

    .content-container {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-top: 100px;

      @media only screen and (max-width: $tablet-breakpoint) {
        flex-flow: column;
        align-items: flex-start;
        gap: 25px;
      }

      .links-container {
        ul.list-item {
          flex-direction: column;
          margin: 0px;
          font-weight: 400;
          line-height: 30px;
          text-align: left;
          li {
            padding: 0px;
            padding-bottom: 2px;
            border: none;
            a {
              border-bottom: 1px solid $white;
              color: $white;
              line-height: 24px;
            }
          }
        }
        .copyright-year {
          padding-bottom: 2px;
        }
      }

      .address-contact-container {
        display: flex;
        justify-content: flex-start;
        gap: 25px;

        @media only screen and (max-width: $tablet-breakpoint) {
          flex-flow: column;
        }

        p.headline {
          padding-bottom: 15px;
          border-bottom: 1px solid $white;
          margin-bottom: 19px;
          font-weight: 700;
          line-height: 36px;
        }

        .details {
          span {
            display: block;
            padding-bottom: 2px;
          }
        }

        p {
          text-align: left;
        }
      }
    }
  }
}
