#footer {
  margin-top: $footer-vertical-spacing-top;
  text-align: center;

  ul.footer-logos {
    display: grid;
    align-items: center;
    justify-content: center;
    padding: $pre-footer-vertical-spacing 0;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: $spacing-medium;
    gap: 60px 20px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    list-style: none;

    &.s {
      grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    }

    &.l img {
      max-width: min(100%, 240px);
      max-height: 165px;
    }

    &.s img {
      max-width: min(100%, 175px);
      max-height: 120px;
    }
  }

  .footer {
    padding: 4.2rem 2rem;
    background-color: $lightgrey;
    font-size: 18px;

    a.powered-by {
      color: $blue-for-grey-contrast;
      font-size: 14px;
    }
    .footer-branding {
      font-size: 14px;
    }

    .footer-message {
      font-weight: $bold;
      a {
        color: $blue-for-grey-contrast;
        font-weight: inherit;
        text-decoration: underline;
      }
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding-left: 0;
      margin-top: 1.4rem;
      list-style: none;

      li {
        padding: 0 7px;
        font-size: 18px;

        a {
          color: $blue-for-grey-contrast;
        }
      }
    }

    ul.footer-links li {
      border-right: 1px solid #205c90;
      &:last-of-type {
        border: none;
      }
    }

    .logo {
      display: flex;
      justify-content: center;
      margin: 5rem 0 1.8rem 0;

      a {
        display: block;
        max-width: 200px;
        max-height: 80px;

        img {
          max-width: 100%;
          height: auto;
        }
      }
    }
  }
}
