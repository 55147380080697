// axolote sprint 2025
:root {
  body.section-plone-axolote-sprint-2025 {
    .breadcrumbs {
      display: none;
    }

    .blocks-group-wrapper,
    .slot-outer-container,
    .block,
    [class='block'] .ui.message {
      background-color: #267470 !important;
      * {
        color: #e7ffe1;
      }

      h1,
      h2,
      h3 {
        color: #ffdeff !important;
      }
    }

    [class='block'] .ui.message p > .button {
      color: #000 !important;
    }

    // Add text-over image as pseudoelements for first block
    #page-document .blocks-group-wrapper:first-child .block.image:first-child {
      figure::before {
        position: absolute;
        display: grid;
        width: 100%;
        height: 100%;
        align-content: end;
        padding-left: 10%;
        background: rgba(79, 85, 93, 0.4);
        content: 'axolote';
        font-size: 4cqw;
        font-weight: 800;
        text-align: center;
        text-orientation: upright;
        writing-mode: vertical-rl;
      }

      figure::after {
        position: absolute;
        top: 50%;
        right: 0;
        display: block;
        height: 20%;
        flex-wrap: wrap;
        padding-top: 4%;
        padding-right: 3rem;
        padding-bottom: 5%;
        padding-left: 3rem;
        background-color: black;

        content: 'June 23rd - 27th, CDMX';
        font-size: 4cqw;
        font-weight: 800;
      }
    }

    // Filter colors of last block
    .blocks-group-wrapper:last-child {
      .block.image:last-child {
        figure.center.large {
          img {
            filter: brightness(0) saturate(100%) invert(98%) sepia(16%)
              saturate(900%) hue-rotate(69deg) brightness(100%);
          }
        }
      }
    }

    #main header {
      --header-background: #267470;
      background-color: #e7ffe1 !important;
      background-color: #267470 !important;
    }

    .blocks-group-wrapper *,
    [class^='block-editor-'] div,
    header .q.container {
      --font-color: #e7ffe1;
      --background-color: #267470;
      --primary-foreground-color: #e7ffe1;
      --primary-color: #267470;
      --primary-foreground-color: #e7ffe1;
      --theme-color: #267470;
      --theme-foreground-color: #e7ffe1;
    }

    #footer {
      --footer-background: #267470;
      background: var(--footer-background);
    }
  }
}

// axolote sprint 2024
:root {
  body.section-plone-axolote-sprint-2024 {
    .breadcrumbs {
      display: none;
    }
    .blocks-group-wrapper,
    .slot-outer-container,
    .block,
    [class='block'] .ui.message {
      background-color: #b2311b !important;
      * {
        color: #ffffe1;
      }

      h1,
      h2,
      h3 {
        color: rgb(180, 202, 231) !important;
      }
    }

    [class='block'] .ui.message p > .button {
      color: #000 !important;
    }

    // Add text-over image as pseudoelements for first block
    #page-document .blocks-group-wrapper:first-child .block.image:first-child {
      figure::before {
        position: absolute;
        display: grid;
        width: 100%;
        height: 100%;
        align-content: end;
        padding-left: 10%;
        background: rgba(79, 85, 93, 0.4);
        content: 'axolote';
        font-size: 4cqw;
        font-weight: 800;
        text-align: center;
        text-orientation: upright;
        writing-mode: vertical-rl;
      }

      figure::after {
        position: absolute;
        top: 50%;
        right: 0;
        display: block;
        height: 20%;
        flex-wrap: wrap;
        padding-top: 4%;
        padding-right: 3rem;
        padding-bottom: 5%;
        padding-left: 3rem;
        background-color: black;

        content: 'April 15th - 19th, CDMX';
        font-size: 4cqw;
        font-weight: 800;
      }
    }
    // Filter colors of last block
    .blocks-group-wrapper:last-child {
      .block.image:last-child {
        figure.center.large {
          img {
            filter: brightness(0) saturate(100%) invert(97%) sepia(20%)
              saturate(400%) hue-rotate(7deg) brightness(105%);
          }
        }
      }
    }

    #main header {
      --header-background: #b2311b;
      background-color: #ffffe1 !important;
      background-color: #b2311b !important;
    }

    .blocks-group-wrapper *,
    [class^='block-editor-'] div,
    header .q.container {
      --font-color: #ffffe1;
      --background-color: #b2311b;
      --primary-foreground-color: #ffffe1;
      --primary-color: #b2311b;
      --primary-foreground-color: #ffffe1;
      --theme-color: #b2311b;
      --theme-foreground-color: #ffffe1;
    }

    #footer {
      --footer-background: #b2311b;
      background: var(--footer-background);
    }
  }
}
