#page-add,
#page-edit,
#page-document {
  .block.image.has--block-width--full img {
    max-height: 829px;
    aspect-ratio: var(--image-aspect-ratio);
    object-fit: cover;
  }
}

.block.image img {
  aspect-ratio: var(--image-aspect-ratio);
  object-fit: cover;
}

body.contenttype-success-story
  #page-document
  > .blocks-group-wrapper
  .block.image:first-child {
  figure {
    margin-top: 0;
  }
}

#page-document > .blocks-group-wrapper .block.image:first-child {
  figure {
    margin-top: 0;
  }
}

#page-document > .blocks-group-wrapper .block.image.has--block-width--full {
  figcaption {
    padding: 0 20px;
  }
}
