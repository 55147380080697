body header.header-wrapper {
  background-color: var(--background-color, $brand-color);

  .header {
    .mobile-nav .hamburger-wrapper {
      .hamburger:before,
      .hamburger::after,
      .hamburger-inner:after,
      .hamburger-inner::before {
        // This one is pre-vlt 6
        background-color: var(--font-color, #fff);
      }
    }
  }
}

.header-wrapper .header .logo-nav-wrapper {
  padding-top: 32px;
  padding-bottom: 105px;
}

.header-wrapper .header .logo-nav-wrapper .navigation {
  align-self: initial;
}

.header-wrapper .header .search-wrapper {
  align-items: center;
  gap: 25px;

  .contact-link {
    padding: 8px 20px;
    border: 1px solid $white;
    background: $white;
    color: $brand-color;
    font-size: 16px;
    font-weight: 900;
    line-height: 20px;
  }
}

.navigation .item {
  color: var(--font-color, #fff);
  &.active,
  &:hover {
    padding-bottom: 15px;
    border-bottom: 1px solid $white;
  }
}

.language-selector {
  // display: flex;
  // flex: 1 1 0;
  // flex-direction: row-reverse;
  margin-right: 0;
  text-transform: uppercase;

  a {
    color: var(--font-color, #fff);
  }
}
