.blocks-group-wrapper {
  background-color: var(--background-color);
}

.blocks-group-wrapper:first-child:has(.block.landingPageTitle) {
  padding-top: 0;
}

#page-document {
  .block.landingPageTitle {
    max-width: 100%;

    h1,
    p {
      @include default-container-width();
      @include adjustMarginsToContainer($default-container-width);
    }
  }
}

#page-edit .q.container .block-editor-testimonials {
  @include default-container-width();
  @include adjustMarginsToContainer($default-container-width);
}

.block.landingPageTitle {
  background-color: var(--background-color, $brand-color);

  h1,
  p {
    color: #fff;
  }

  p {
    // Padding in the last element to not affect the edit wrappers
    // due to the duplication of classnames there
    padding-bottom: 40px;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 33px; /* 137.5% */
  }

  .landingPageTitleFirstHeading {
    margin-bottom: 80px;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 56px; /* 116.667% */
  }
}

.block.teaser {
  background-color: var(--background-color);

  // &[style*='--background-color'] {
  //   padding: 20px 0;
  // }

  .teaser-item {
    color: var(--font-color);
  }
}

.block.heading {
  background-color: var(--background-color);
  color: var(--font-color);
}

.block.separator {
  background-color: var(--background-color);

  .line::after {
    border-top: 1px solid var(--font-color, $black);
  }
}

.blocks-group-wrapper > .block.teaser .teaser-item.default,
.block-editor-teaser .block.teaser .teaser-item.default {
  border-bottom: 1px solid var(--font-color);
}
.block.listing.grid .listing-item .card-container {
  background-color: #fff;
}
.block.gridBlock {
  background-color: var(--background-color);

  .headline {
    color: var(--font-color);
  }

  .teaser-item {
    background-color: var(--background-color);
    color: var(--font-color);
  }
}

.block.gridBlock,
.block-editor-gridBlock {
  &[style*='--background-color'] .block.teaser .teaser-item .content {
    padding: 0 !important;
  }
}

.block.gridBlock .block.image img,
#page-edit .block-editor-gridBlock .block.gridBlock .block.image img {
  aspect-ratio: var(--image-aspect-ratio, 16/9) !important;
}

#page-document
  .blocks-group-wrapper
  .block.separator.has--align--left-default
  .line {
  max-width: var(--default-container-width);
  margin-right: auto;
  margin-left: auto;

  &::after {
    width: 165px;
  }
}

#page-edit,
#page-add {
  .block-editor-separator.has--align--left-default
    .block.separator.inner
    .block.separator
    .block.separator
    .line {
    max-width: var(--default-container-width);
    margin-right: auto;
    margin-left: auto;

    &::after {
      width: 165px;
    }
  }
}

.block.logos {
  .ui.grid {
    .column {
      &:first-of-type {
        .logo-block {
          align-self: flex-start;
        }
      }

      &:last-of-type {
        .logo-block {
          align-self: flex-end;
        }
      }

      @media only screen and (max-width: $tablet-breakpoint) {
        &:first-of-type,
        &:last-of-type {
          .logo-block {
            align-self: unset;
          }
        }
      }
    }
  }
}

// Testimonials

.block.testimonials {
  &:not(.inner):not([role='presentation']) {
    padding-bottom: 0;
  }

  .testimonials-wrapper {
    margin: 0 80px;

    @media only screen and (max-width: $tablet-breakpoint) {
      margin: 0;
    }
  }

  .testimonial-slide .grid-teaser-item.top {
    background: #f3f4f6;
  }

  .testimonial {
    margin: 100px 150px;
    margin-bottom: 0;
    font-size: 30px;
    font-style: normal;
    font-weight: 300;
    hyphens: none;
    line-height: 42px;
    word-break: keep-all;

    @media only screen and (max-width: $tablet-breakpoint) {
      margin: 20px 45px;
      font-size: 20px;
      line-height: 30px;
    }
  }

  .teaser-item-title .person {
    margin: 50px 150px;

    @media only screen and (max-width: $tablet-breakpoint) {
      flex-direction: column;
      align-items: center;
      margin: 20px 45px;
    }

    div {
      margin-top: auto;
      margin-bottom: auto;
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      line-height: 24px;

      @media only screen and (max-width: $tablet-breakpoint) {
        margin-top: 20px;
        text-align: center;
      }

      .position {
        font-weight: 300;
      }

      .name {
        font-weight: 900;
        @media only screen and (max-width: $tablet-breakpoint) {
          text-align: center;
        }
      }
    }
  }
}

// Logos

#page-document .block.logos.next--is--logos {
  margin-bottom: 63px;
}

// Masonry

// #page-document .blocks-group-wrapper .block.masonry {
//   max-width: var(--default-container-width);
//   margin-right: auto;
//   margin-left: auto;
// }

.block.masonry,
.block-editor-masonry {
  .block.image figure.center {
    margin: 0;
  }
}

.block-editor-masonry {
  background-color: var(--background-color);
}

#page-document
  .blocks-group-wrapper
  .block.masonry
  .block.image.align.center
  figure {
  max-width: initial;
}

#page-document
  .blocks-group-wrapper
  > .block.block.masonry.next--is--same--block-type {
  margin-bottom: 20px;
}

#page-add .block-editor-masonry .block-editor-image.contained,
#page-edit .block-editor-masonry .block-editor-image.contained {
  margin: 0;
}

#page-document .block.masonry,
#page-add .block.masonry .blocks-form,
#page-edit .block.masonry .blocks-form {
  &.grid2-mix-left {
    display: grid;
    grid-gap: 20px;
    grid-template-areas:
      'left righttop'
      'left rightbottom';

    > :nth-child(1) {
      grid-area: left;
    }
    > :nth-child(2) {
      grid-area: righttop;
    }
    > :nth-child(3) {
      grid-area: rightbottom;
    }
  }

  &.grid2-mix-right {
    display: grid;
    grid-gap: 20px;
    grid-template-areas:
      'lefttop right'
      'leftbottom right';

    > :nth-child(1) {
      grid-area: right;
    }
    > :nth-child(2) {
      grid-area: lefttop;
    }
    > :nth-child(3) {
      grid-area: leftbottom;
    }
  }

  &.grid2-standard {
    display: grid;
    grid-gap: 20px;
    grid-template-areas: 'left right';

    > :nth-child(1) {
      grid-area: left;
    }
    > :nth-child(2) {
      grid-area: right;
    }
    > :nth-child(3) {
      display: none !important;
    }
  }

  &.grid1-standard {
    display: grid;
    grid-gap: 20px;
    grid-template-areas: 'center';

    :nth-child(1) {
      grid-area: center;
    }
    > :nth-child(2) {
      display: none !important;
    }
    > :nth-child(3) {
      display: none !important;
    }
  }
}

#page-document .block.masonry .block.hovered::before,
#page-document .block.masonry .block:hover::before {
  display: none;
}

// kitconcept.com does not have inverse bg colors in grids
.block.gridBlock .block.teaser a {
  color: var(--font-color);
}
.block.gridBlock .block.teaser .teaser-item .content {
  padding: 0;
}
.block.listing.grid .listing-item .content {
  padding: 0;
}

/* we have this problem in VLT. I opened the issue #452 and will fix it.
* But for currently I am fixing it here.
*
*/
.block.slider {
  .teaser-item.top {
    a {
      @media only screen and (max-width: $tablet-breakpoint) {
        text-decoration: none;
      }
    }
  }

  .ui.button {
    color: #fff;
  }
}
