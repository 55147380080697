// Pre-BlockModelv3 - Layout
@mixin variable-container-width() {
  max-width: var(--block-width, --layout-container-width);
  margin-right: auto;
  margin-left: auto;
}

// Container widths for configurable via block styling wrapper
[style*='--block-width: var(--narrow-container-width)'] {
  @include adjustMarginsToContainer($narrow-container-width);
}
[style*='--block-width: var(--default-container-width)'] {
  @include adjustMarginsToContainer($default-container-width);
}
[style*='--block-width: var(--layout-container-width)'] {
  @include adjustMarginsToContainer($layout-container-width);
}

// Image block overrides
#page-edit,
#page-add {
  .block-editor-image figure.center {
    @include variable-container-width();
  }

  .block-editor-image.default figure {
    max-width: var(--default-container-width);
  }
}

#page-document .blocks-group-wrapper > .block.image.align.center {
  @include variable-container-width();
}
#page-document .blocks-group-wrapper > .block.image.align.center figure {
  @include variable-container-width();
}
