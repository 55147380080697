@use 'sass:map';

.block.contact {
  .ui.container.padded {
    padding: 0 40px;
  }

  .teaser-item,
  .grid-teaser-item {
    flex-direction: column;
  }

  .teaser-item.top {
    a {
      display: inline;
    }

    .contact-image-wrapper {
      line-height: 0;

      img {
        height: auto;
        aspect-ratio: 1;
        object-fit: cover;
      }
    }

    .contact-description {
      padding: 40px 0px 20px 0px;

      .title {
        h3 {
          font-size: $heading3;
          font-weight: $bold;
          line-height: map-get($line-heights, l);
        }
      }

      .description {
        p {
          font-size: $font-size;
          font-weight: $light;
          line-height: $line-height;
        }
      }
    }
  }
}
